import ImgGrid from "../components/gallery/imgGrid";
import SectionText from "../components/primitive/sectionText";
import { makeupkits } from "../data/index";

const FifthSection = () => {
  return (
    <section className="px-[15px] md:px-[20px]  lg:px-[60px] flex flex-col md:flex-row py-16 md:py-0  md:space-x-[60px] xl:space-x-[154px]  items-center   ">
      <ImgGrid
        image1={makeupkits[0]}
        image2={makeupkits[1]}
        image3={makeupkits[2]}
        image4={makeupkits[3]}
        image5={makeupkits[4]}
        image6={makeupkits[5]}
      />
      <div>
        <SectionText
          title="Custom beauty and wellness products as unique as you."
          description="At Her, we believe you deserve products that are tailor made for your unique needs , goals and preferences.
With your data, we are committed to producing quality and high performing products made from precise , safe and toxin free ingredients. 
"
        />
      </div>
    </section>
  );
};

export default FifthSection;

import { ChangeEventHandler } from "react";

interface ISubscribeInput {
  email?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: () => void;
  disabled?: boolean;
}

const SubscribeInput = ({
  email,
  onChange,
  onSubmit,
  disabled,
}: ISubscribeInput) => {
  return (
    // <form>
    <div className="flex w-full justify-between  items-center space-x-3.5 ">
      <input
        placeholder="Enter email address"
        name="email"
        className=" text-sm rounded-md flex flex-1  px-3.5 py-[9px]  text-black border-[0.5px] border-borderColor focus:outline-1 focus:outline-grey200 placeholder:font-medium"
        value={email}
        onChange={onChange}
      />

      <button
        type="submit"
        onClick={onSubmit}
        disabled={disabled}
        className=" font-semibold text-sm text-white bg-black md:px-[19px] md:py-[9px]  px-3 py-2 rounded-md md:rounded:lg active:scale-90 transition duration-150"
      >
        Join waitlist
      </button>
    </div>
    // </form>
  );
};

export default SubscribeInput;

import { IActionProps } from "./experienceAction";

const SectionText = ({ title, description }: IActionProps) => {
  return (
    <div className=" text-center md:text-left max-w-[607px] space-y-5">
      <h4 className=" text-4xl md:text-[2.6rem] lg:text-5xl font-bold md:leading-[60px]">
        {title}
      </h4>
      <div className="md:max-w-[510px]">
        <p className="text-[22px] text-grey200">{description}</p>
      </div>
    </div>
  );
};

export default SectionText;

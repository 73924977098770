
// import Image1 from '../assets/imgs/makeupkit1.png'
import Eyebrow from "../assets/imgs/eyebrow.jpg";
import CleanFace from "../assets/imgs/cleanFaceImg.png";
import Needle from "../assets/imgs/needle.jpg";
import Eyelashes from "../assets/imgs/eyeLashes.jpg";
import Ebony from "../assets/imgs/Ebony.jpg";
import Fingers from "../assets/imgs/fingers.jpg";

import Makeup1 from "../assets/imgs/makeupkit1.png";
import Makeup2 from "../assets/imgs/makeupkit2.png";
import Makeup3 from "../assets/imgs/makeupkit3.png";
import Makeup4 from "../assets/imgs/makeupkit4.png";
import Makeup5 from "../assets/imgs/makeupkit5.png";
import Makeup6 from "../assets/imgs/makeupkit6.png";


//slide images
import FirstSlide from "../assets/imgs/slideimg1.png"
import SeconfSlide from "../assets/imgs/slideimg2.jpg"
import ThirdSlide from "../assets/imgs/slideimg3.png"
import FourthSlide from "../assets/imgs/slideimg4.png"
import FifthSlide from "../assets/imgs/slideimg5.jpg"
import SixthSlide from "../assets/imgs/slideimg6.jpg"
import SeventhSlide from "../assets/imgs/slideimg7.jpg"
import EightSlide from "../assets/imgs/slideimg8.png"
import NinthSlide from "../assets/imgs/slideimg9.jpg"
import TenthSlide from "../assets/imgs/slideimg10.jpg"
import EleventhSlide from "../assets/imgs/slideimg11.jpg"
import TwelfthSlide from "../assets/imgs/slideimg12.jpg"




export const experienceActions = [
    {

        title: "Access",
        description:
            " Licensed and qualified beauty professionals available to solve your toughest beauty challenges and curate the best experiences for you.",
    },
    {

        title: "Support",
        description:
            "We are here for the long term and so we guarantee continuous support from our professionals and technology solutions."
    },
    {

        title: "Innovation",
        description:
            "Our data and AI driven technology solutions are guaranteed to provide a 99.9% accurate beauty diagnosis as well as a recommendation of personalised solutions that meet your specific needs.",
    },
    {

        title: "Personalisation",
        description:
            "Based on your unique needs, we would produce the most effective beauty solutions using the most safe and toxin free ingredients.",
    },
];


export const faceImages = [
    Eyebrow,
    CleanFace,
    Needle,
    Eyelashes,
    Ebony,
    Fingers


]

export const makeupkits = [
    Makeup1,
    Makeup2,
    Makeup3,
    Makeup4,
    Makeup5,
    Makeup6,
]

export const slideImages = [
    FirstSlide,
    SeconfSlide,
    ThirdSlide,
    FourthSlide,
    FifthSlide,
    SixthSlide,
    SeventhSlide,
    TenthSlide,
    EightSlide,
    NinthSlide,
    EleventhSlide,
    TwelfthSlide,

]
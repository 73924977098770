import SubscribeInput from "../components/form/input";
import { IFormProps } from "./mainSection";

const CommittedSection = ({
  onSubmit,
  onChange,
  disabled,
  email,
}: IFormProps) => {
  return (
    <section className="w-full bg-greyBg space-y-[34px] flex flex-col items-center  text-center py-[81px] px-[18px] md:px-[60px] text-5xl font-bold  ">
      <div className="max-w-[985px]">
        <h4>
          We are committed to creating magic and living the soft life with you.
        </h4>
      </div>
      <div className="max-w-[502px] w-full">
        <SubscribeInput
          onSubmit={onSubmit}
          onChange={onChange}
          email={email}
          disabled={disabled}
        />
      </div>
    </section>
  );
};

export default CommittedSection;

import ImgGrid from "../components/gallery/imgGrid";
import SectionText from "../components/primitive/sectionText";
import { faceImages } from "../data/index";

const ThirdSection = () => {
  return (
    <section className=" px-[15px] md:px-[20px]  lg:px-[60px] flex flex-col md:flex-row py-16 md:py-0  md:space-x-[60px] xl:space-x-[154px]  items-center   ">
      <ImgGrid
        image1={faceImages[0]}
        image2={faceImages[1]}
        image3={faceImages[2]}
        image4={faceImages[3]}
        image5={faceImages[4]}
        image6={faceImages[5]}
      />
      <div>
        <SectionText
          title="Building relationships, curating experiences and solving challenges."
          description="At our world class standard beauty and wellness ateliers fully equipped with licensed and qualified professionals, we are guaranteeing memorable experiences , deepened relationships and high performing solutions.
"
        />
      </div>
    </section>
  );
};

export default ThirdSection;

import ExperienceAction, {
  IActionProps,
} from "../components/primitive/experienceAction";

interface IExperienceSectionProps {
  experienceActions: IActionProps[];
}

// px-[60px] grid grid-cols-2 gap-x-[24px] items-center justify-center pt-[102px] pb-[65px]

const ExperienceSection = ({ experienceActions }: IExperienceSectionProps) => {
  return (
    <section className="bg-paleSkyBg text-white px-[10px] md:px-[40px] lg:px-[60px] py-[100px] space-y-20 md:space-y-0 flex items-center flex-col  md:flex-row md:space-x-[40px] xl:space-x-[86px] ">
      <div className=" max-w-[400px]  lg:max-w-[500px]  xl:max-w-[600px] ">
        <h4 className=" text-center md:text-left font-semibold text-4xl lg:text-5xl">
          Creating personalised experiences and taking the guesswork out of
          beauty and wellness.
        </h4>
      </div>
      <div className=" grid grid-cols-1 lg:grid-cols-2 gap-x-[40px]  xl:gap-x-[48px] gap-y-10 ">
        {experienceActions.map((action, index) => (
          <ExperienceAction
            key={index}
            title={action.title}
            description={action.description}
          />
        ))}
      </div>
    </section>
  );
};

export default ExperienceSection;

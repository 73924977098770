import { useState } from "react";
import ExperienceSection from "./containers/experienceSection";
import Footer from "./containers/footer";
import MainSection from "./containers/mainSection";
import SectionWithIphone from "./containers/sectionWithIphone";
import { experienceActions } from "./data/index";

import CommittedSection from "./containers/committedBannerSection";

import ThirdSection from "./containers/thridSection";
import FifthSection from "./containers/fifthSection";

const { REACT_APP_BASE } = process.env;

function App() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const onSubmit = () => {
    setLoading(true);
    if (validateEmail(email)) {
      fetch(REACT_APP_BASE as string, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: email,
        }),
      })
        .then((res) => {
          setLoading(false);
          setEmail("");

          return res.json();
        })
        .then((response) => console.log(response))
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
    }
  };
  return (
    <div className="font-bai">
      <MainSection
        email={email}
        onSubmit={onSubmit}
        disabled={loading}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <ExperienceSection experienceActions={experienceActions} />
      <ThirdSection />
      <SectionWithIphone />
      <FifthSection />
      <CommittedSection
        email={email}
        onSubmit={onSubmit}
        disabled={loading}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <Footer
        email={email}
        onSubmit={onSubmit}
        disabled={loading}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
    </div>
  );
}

export default App;

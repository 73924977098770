import { CircleCheckIcon } from "../../assets/icons";

export interface IActionProps {
  title: string;
  description: string;
}

const ExperienceAction = ({ title, description }: IActionProps) => {
  return (
    <div className="flex  space-x-3 items-start  border-white  ">
      <div className="h-full">
        <CircleCheckIcon width={20} height={20} />
      </div>
      <div className="flex flex-col space-y-2 w-[375px]  -m-2 ">
        <p className="text-2xl font-semibold capitalize ">{title}</p>
        <p className=" text-base md:text-lg leading-[22px]  ">{description}</p>
      </div>
    </div>
  );
};

export default ExperienceAction;

// import { imageGrid } from "../../data/index";
interface IImageGridProps {
  image1: string;
  image2?: string;
  image3?: string;
  image4?: string;
  image5?: string;
  image6?: string;
}

const ImgGrid = ({
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
}: IImageGridProps) => {
  return (
    <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-5 items-center order-2 md:order-none mt-12 md:mt-0">
      <div className="flex flex-col space-y-3 md:space-y-5">
        <div className=" md:w-[200px] lg:w-[283px] h-[77px]  shadow-sm shadow-greyBg">
          <img
            src={image1}
            alt="eyebrow"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "0% 1%",
            }}
            className="rounded-br-[4px] rounded-bl-[4px]  "
          />
        </div>
        <div className=" xs:w-full xs:h-full  md:w-[200px] lg:w-[283px] sm:h-[386px]  shadow-sm shadow-greyBg">
          <img
            src={image2}
            alt="eyebrow"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "0% 1%",
            }}
            className="rounded-[4px]  "
          />
        </div>
        <div className=" md:w-[200px] lg:w-[283px] h-[280px]  shadow-sm shadow-greyBg">
          <img
            src={image3}
            alt="eyebrow"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "0% 75%",
            }}
            className="rounded-tr-[4px] rounded-tl-[4px]"
          />
        </div>
      </div>
      <div className="flex flex-col space-y-5">
        <div className=" md:w-[200px] lg:w-[283px] h-[217px]  shadow-sm shadow-greyBg mt-5 sm:mt-0">
          <img
            src={image4}
            alt="eyebrow"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "0% 55%",
            }}
            className="rounded-br-[4px] rounded-bl-[4px]"
          />
        </div>

        <div className=" md:w-[200px] lg:w-[283px] h-[414px]  ">
          <img
            src={image5}
            alt="eyebrow"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "0% 1%",
            }}
            className="rounded-[4px] "
          />
        </div>
        <div className=" md:w-[200px] lg:w-[283px] h-[112px]  shadow-sm shadow-greyBg">
          <img
            src={image6}
            alt="eyebrow"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              // objectPosition: "0% 1%",
            }}
            className="rounded-tr-[4px] rounded-tl-[4px]"
          />
        </div>
      </div>
    </div>
  );
};

export default ImgGrid;

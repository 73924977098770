import SectionText from "../components/primitive/sectionText";
import FirstIphone from "../assets/imgs/firstPhone.png";
import SecondPhone from "../assets/imgs/secondPhone.png";

const SectionWithIphone = () => {
  return (
    <section className="px-[30px] lg:px-[60px] space-y-20 md:space-y-0  flex flex-col md:flex-row md:space-x-[60px] lg:space-x-[94px]  items-center py-[64px] bg-greyBg">
      <SectionText
        title="Data-backed and AI integrated solutions to help you take control."
        description="We are equipping you with technology that
                     provides an immediate and 99.9% accurate 
                     diagnosis alongside a recommendation of toxin 
                     free products specifically directed at the 
                     diagnosis results. "
      />
      <div className="flex space-x-[30px] lg:space-x-[46px]">
        <div className="h-[602px]">
          <img
            src={FirstIphone}
            alt="iphone"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </div>
        <div className="pt-[80px] h-full w-[300px]">
          <img
            src={SecondPhone}
            alt="iphone"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default SectionWithIphone;

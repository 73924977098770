import { ChangeEventHandler, useEffect, useState } from "react";
import Logo from "../components/primitive/logo";
import SubscribeInput from "../components/form/input";
import { slideImages } from "../data/index";

export interface IFormProps {
  email: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: () => void;
  disabled?: boolean;
}

const MainSection = ({ onChange, onSubmit, disabled, email }: IFormProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % slideImages.length);
    }, 2000);
    return () => clearInterval(timer);
  }, [currentIndex]);

  return (
    <div className="relative">
      <div className="py-6 pl-[20px] xs:pl-[30px] sm:pl-[60px]  flex flex-col absolute  w-full">
        <Logo />
      </div>
      <div className=" grid sm:grid-cols-3">
        <div className="sm:block hidden"></div>
        <div
          className=" sm:col-span-2 "
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <img
            src={slideImages[currentIndex]}
            alt="slide"
            className="object-cover  transition-transform duration-300 ease-in-out"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </div>
      </div>

      <div className=" absolute max-w-[817px] h-full top-0 right-[20px] xs:right-[30px] sm:right-[60px] left-[20px] xs:left-[30px] sm:left-[60px]  flex flex-col justify-center xs:text-white md:text-slate-900 ">
        <div className="flex flex-col space-y-[22px] text-center md:text-left   mb-8 -mt-10">
          <h2 className=" text-[1.8rem] xs:text-[2rem] leading-[38px] sm:text-[2.5rem] xs:leading-[45px] md:text-5xl md:leading-[60px] font-bold  ">
            Transforming lives by reimagining the beauty and wellness
            experience.
          </h2>
          <p className="md:text-grey200 xs:text-white font-medium text-xl sm:text-2xl leading-[30px]">
            For Women , By Women.
          </p>
        </div>
        <div className="max-w-[488px] text-center md:text-left">
          <SubscribeInput
            onSubmit={onSubmit}
            onChange={onChange}
            disabled={disabled}
            email={email}
          />
        </div>
      </div>
    </div>
  );
};

export default MainSection;

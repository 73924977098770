const Logo = () => {
  return (
    <div>
      <h1 className="font-abril text-[2rem] sm:text-[2.5rem] md:text-5xl font-black ">Her.</h1>
    </div>
  );
};

export default Logo;



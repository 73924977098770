import { InstagramIcon, Linkedin, TikTok } from "../assets/icons";
import SubscribeInput from "../components/form/input";
import Logo from "../components/primitive/logo";
import { IFormProps } from "./mainSection";

const Footer = ({ onChange, onSubmit, disabled, email }: IFormProps) => {
  return (
    <footer className=" pt-[50px] px-[30px] lg:px-[60px] ">
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-0">
        <div className="flex flex-col space-y-4 mb-[48px] ">
          <Logo />
          <div className="flex  justify-centeritems-center space-x-4 ">
            <a
              href="https://instagram.com/her.global?igshid=NDk5N2NlZjQ="
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon className=" hover:scale-90 transition duration-150" />
            </a>
            <a
              href="https://www.linkedin.com/company/her-glob/"
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin className=" hover:scale-90 transition duration-150" />
            </a>
            <a
              href="https://www.tiktok.com/@herglobal?_t=8YxhEuhjrOQ&_r=1"
              target="_blank"
              rel="noreferrer"
            >
              <TikTok className=" hover:scale-90 transition duration-150" />
            </a>
          </div>
        </div>
        <div className="space-y-[11px]">
          <h6 className="text-xl font-semibold">Contact Us</h6>
          <p className="not-italic font-medium text-base">
            <a href="mailto:webmaster@example.com">Info@herglobal.co</a>.
          </p>
          <p className="not-italic font-medium text-base">
            <a href="tel:+2348120453828">+2348120453828</a>
          </p>
        </div>
        <div className="space-y-2.5  justify-self-center col-span-2 text-center lg:text-left lg:col-auto lg:justify-self-start">
          <h6 className="text-xl font-semibold">We are live soon</h6>
          <p className="font-medium">Be the first to know when we launch!</p>
          <SubscribeInput
            onSubmit={onSubmit}
            onChange={onChange}
            email={email}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="border-t py-6 font-medium text-center lg:text-end mt-8 lg:mt-0">
        <span>&copy; 2023 Her Global. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
